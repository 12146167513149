import { createGlobalStyle } from "styled-components";
import PoppinsBold from "./fonts/Poppins-SemiBold.ttf";
import PoppinsExtraBold from "./fonts/Poppins-ExtraBold.ttf";
import PoppinsMedium from "./fonts/Poppins-Medium.ttf";
import PoppinsLight from "./fonts/Poppins-Light.ttf";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'PoppinsExtraBold';
        src: url(${PoppinsExtraBold});
    }

    @font-face {
        font-family: 'PoppinsBold';
        src: url(${PoppinsBold});
    }
    
    @font-face {
        font-family: 'PoppinsLight';
        src: url(${PoppinsLight});
    }

    @font-face {
        font-family: 'PoppinsMedium';
        src: url(${PoppinsMedium});
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    ul {
        list-style-type: none;
    }

    body { 
        font-family: 'PoppinsLight', 'Arial', sans-serif;
        margin: 0;
        outline: none;
    }

    * {
        outline: none;
        margin: 0;
        padding: 0;
    }
`;
