/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FC, Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { HeaderStyled } from "./styled";
import { HeaderImages } from "design-system/images";
import { HeaderProps } from "design-system/domain";
import { colors } from "design-system/theme";
import { RootState } from "store";
import { Http } from "data/protocols/services/api";
import { HttpRegistrationRedirect } from "services/account-user/redirect";
import { StorageHelper } from "utils/functions/storage/storage";
import { UserState } from "store/domain";
import { blockRoutesToShowElement } from "utils/consts/blocked-routes-show-header-and-footer";

const Header: FC<HeaderProps> = ({ backgroundColor, hideFirstRowHeader }) => {
	const [showSubItemsMenu, setShowSubItemsMenu] = useState<boolean>(false);
	const [showUserMenu, setshowUserMenu] = useState<boolean>(false);
	const [
		modalRedirectComplementAccountIsOpen,
		setModalRedirectComplementAccountIsOpen,
	] = useState<boolean>(false);
	const [modalUserIsNotLogged, setModalUserIsNotLogged] =
		useState<boolean>(false);
	const [modalOptionComunication, setModalOptionComunication] =
		useState<boolean>(false);
	const [queryParamValue, setQueryParamValue] = useState<string>("");
	const { user } = useSelector((state: RootState) => state);
	const [isRedirect, setIsRedirect] = useState<boolean>(false);
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const http = new Http();
	const storageHelper = new StorageHelper();
	const [modal, setModal] = useState<boolean>(false);
	const httpRegistrationRedirect = new HttpRegistrationRedirect(
		http,
		storageHelper
	);

	function goToConecta() {
		window.location.href = "https://conectapontos.com/";
	}

	return (
		<>
			{!hideFirstRowHeader && (
				<HeaderStyled.Container
					backgroundColor={backgroundColor}
					hideFirstRowHeader={hideFirstRowHeader}
				>
					<HeaderStyled.LogoLink>
						<HeaderStyled.LogoHeader
							alt={"Logo do ConectaAG"}
							className="pointer"
							src={HeaderImages.logoConecta}
							onClick={goToConecta}
						/>
					</HeaderStyled.LogoLink>
				</HeaderStyled.Container>
			)}
		</>
	);
};

export default Header;
