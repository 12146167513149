import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { appReducer } from "store";
import { colors, GlobalStyle } from "design-system/theme";
import { Footer, Header, Layout } from "./design-system/components";

//Import Pages
import { PageElo } from "./pages/PageElo";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { MaintenancePage } from "./pages/Elo";

const App: FC = () => {
	const persistor = persistStore(appReducer);
	if (process.env.REACT_APP_CONECTAPONTOS_MAINTENANCE === "isMaintenance") {
		return <MaintenancePage />;
	}
	return (
		<Provider store={appReducer}>
			<PersistGate persistor={persistor}>
				<GlobalStyle />
				<Layout>
					<Header
						hideFirstRowHeader={false}
						backgroundColor={colors.generics.white}
					/>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<PageElo />} />
						</Routes>
					</BrowserRouter>
					<Footer />
				</Layout>
			</PersistGate>
		</Provider>
	);
};

export default App;
