import styled from "styled-components";
import { colors } from "design-system/theme";
import { TitleIcons } from "design-system/icons";
import { breakpoints } from "design-system/theme/breakpoints";

const Container = styled.div`
	padding: 80px 0 0 0;

	${breakpoints.up("sm")} {
		padding: 0 80px;
	}
`;

const Row = styled.div`
	/* height: 125px;
    padding: 50px 50px 0; */
`;

const Col12 = styled.div`
	display: flex;
	flex: 0 0 100%;
	max-width: 100%;

	${breakpoints.up("sm")} {
	}
`;

const TitleContent = styled.h2`
	/*background: url(${TitleIcons.SquareIcon});*/
	background-repeat: no-repeat;
	color: ${colors.primary.red};
	font-size: 30px;
	font-weight: 700;

	${breakpoints.down("sm")} {
		margin-left: 10px;
	}
`;

const IconSquare = styled.img`
	margin-right: 20px;
`;

export const TitlePageStyled = {
	Col12,
	Container,
	IconSquare,
	Row,
	TitleContent,
};
